import React, { useEffect, useState } from "react";
import "./App.css";
import { ReactSVG } from "react-svg";

const DEFAULT_URL = "https://app.capito.eu/content/8540ddcc-54b2-4b82-9fbf-98b6e2938116";

function stripNotRequiredHref(url: string): string {
  let match = url.match(/\/#\/(.*)/);
  if (match === undefined || match === null) {
    return DEFAULT_URL;
  }
  return match[1];
}

const App = () => {

  const [newUrl, setNewUrl] = useState(DEFAULT_URL);

  useEffect(() => {
      let legacyUrl = window.location.href;

      let strippedLegacyUrl = stripNotRequiredHref(legacyUrl);
      let splitLegacyUrl = strippedLegacyUrl.split("/");

      let newUrl = `https://app.capito.eu/content/${splitLegacyUrl[0]}/de-at/${splitLegacyUrl[1]}`;

      setNewUrl(newUrl);

      setTimeout(() => {
        window.location.replace(newUrl);
      }, 2000);
    },
    []);

  return (
    <div className="app">
      <div className="container">
        <div className="content">
          <div className="logo-container">
            <div className="logo">
              <ReactSVG src="/web.svg" />
            </div>
          </div>
          <div className="text">
            <div className="headline">
              Wir sind umgezogen!
            </div>
            <div className="message-container">
              <div className="message">
                Sie werden zu unseren <strong>neuen & verbesserten</strong> capitoApp umgeleitet...
              </div>
            </div>
            <div className="loader" />
            <div className="link-container">
              <a href={newUrl} className="link">
                Bitte hier klicken, falls Sie nicht automatisch umgeleitet werden!
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
